import React, { useState } from "react";
import BannerHeader from "../../layouts/global/BannerHeader";
import board from "../../assets/images/events.png";
// events
import event1a from "../../assets/images/EVENTS/bambang cleanup/bambangclean1.jpg";
import event1b from "../../assets/images/EVENTS/bambang cleanup/bambangclean2.jpg";
import event1c from "../../assets/images/EVENTS/bambang cleanup/bambangclean3.jpg";

import event2a from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO1.jpg";
import event2b from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO2.jpg";
import event2c from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO3.jpg";
import event2d from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO4.jpg";
import event2e from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO5.jpg";
import event2f from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO6.jpg";
import event2g from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO7.jpg";
import event2h from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO8.jpg";
import event2i from "../../assets/images/EVENTS/bambang donaation scho/BAMBANGSCHO9.jpg";

import event3a from "../../assets/images/EVENTS/books/Books1.jpg";
import event3b from "../../assets/images/EVENTS/books/Books3.jpg";
import event3c from "../../assets/images/EVENTS/books/Books2.jpg";

import event4a from "../../assets/images/EVENTS/christmas/christmas1.jpg";
import event4b from "../../assets/images/EVENTS/christmas/christmas2.jpg";
import event4c from "../../assets/images/EVENTS/christmas/christmas3.jpg";
import event4d from "../../assets/images/EVENTS/christmas/christmas4.jpg";
import event4e from "../../assets/images/EVENTS/christmas/christmas5.jpg";
import event4f from "../../assets/images/EVENTS/christmas/christmas6.jpg";
import event4g from "../../assets/images/EVENTS/christmas/christmas7.jpg";
import event4h from "../../assets/images/EVENTS/christmas/christmas8.jpg";
import event4i from "../../assets/images/EVENTS/christmas/christmas9.jpg";

import event5a from "../../assets/images/EVENTS/conference dusit/CONFERENCE1.jpg";
import event5b from "../../assets/images/EVENTS/conference dusit/CONFERENCE2.jpg";
import event5c from "../../assets/images/EVENTS/conference dusit/CONFERENCE3.jpg";
import event5d from "../../assets/images/EVENTS/conference dusit/CONFERENCE4.jpg";
import event5e from "../../assets/images/EVENTS/conference dusit/CONFERENCE5.jpg";
import event5f from "../../assets/images/EVENTS/conference dusit/CONFERENCE6.jpg";
import event5g from "../../assets/images/EVENTS/conference dusit/CONFERENCE7.jpg";
import event5h from "../../assets/images/EVENTS/conference dusit/CONFERENCE8.jpg";
import event5i from "../../assets/images/EVENTS/conference dusit/CONFERENCE9.jpg";

import event6a from "../../assets/images/EVENTS/founding/founding1.jpg";
import event6b from "../../assets/images/EVENTS/founding/founding2.jpg";
import event6c from "../../assets/images/EVENTS/founding/founding3.jpg";

import event7a from "../../assets/images/EVENTS/lucena//lucena1.jpg";
import event7b from "../../assets/images/EVENTS/lucena/lucena2.jpg";
import event7c from "../../assets/images/EVENTS/lucena/lucena3.jpg";
import event7d from "../../assets/images/EVENTS/lucena//lucena4.jpg";
import event7e from "../../assets/images/EVENTS/lucena/lucena5.jpg";
import event7f from "../../assets/images/EVENTS/lucena/lucena6.jpg";

import event8a from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada1.jpg";
import event8b from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada2.jpg";
import event8c from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada3.jpg";
import event8d from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada4.jpg";
import event8e from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada5.jpg";
import event8f from "../../assets/images/EVENTS/outreach calzaca tipas tguig/calzada6.jpg";

import event9a from "../../assets/images/EVENTS/pinagsama/PINAGSAMA1.jpg";
import event9b from "../../assets/images/EVENTS/pinagsama/PINAGSAMA2.jpg";
import event9c from "../../assets/images/EVENTS/pinagsama/PINAGSAMA3.jpg";
import event9d from "../../assets/images/EVENTS/pinagsama/PINAGSAMA4.jpg";
import event9e from "../../assets/images/EVENTS/pinagsama/PINAGSAMA5.jpg";
import event9f from "../../assets/images/EVENTS/pinagsama/PINAGSAMA6.jpg";

import event10a from "../../assets/images/EVENTS/silang korean conf/koreanconf1.jpg";
import event10b from "../../assets/images/EVENTS/silang korean conf/koreanconf2.jpg";
import event10c from "../../assets/images/EVENTS/silang korean conf/koreanconf3.jpg";
import event10d from "../../assets/images/EVENTS/silang korean conf/koreanconf4.jpg";
import event10e from "../../assets/images/EVENTS/silang korean conf/koreanconf5.jpg";
import event10f from "../../assets/images/EVENTS/silang korean conf/koreanconf6.jpg";
import event10g from "../../assets/images/EVENTS/silang korean conf/koreanconf7.jpg";
import event10h from "../../assets/images/EVENTS/silang korean conf/koreanconf8.jpg";
import event10i from "../../assets/images/EVENTS/silang korean conf/koreanconf9.jpg";

import event17a from "../../assets/images/EVENTS/gensan/gensan1.jpg";
import event17b from "../../assets/images/EVENTS/gensan/gensan2.jpg";
import event17c from "../../assets/images/EVENTS/gensan/gensan3.jpg";
import event17d from "../../assets/images/EVENTS/gensan/gensan4.jpg";
import event17e from "../../assets/images/EVENTS/gensan/gensan5.jpg";
import event17f from "../../assets/images/EVENTS/gensan/gensan6.jpg";

import event11a from "../../assets/images/EVENTS/sisters of mamry/SOM1.jpg";
import event11b from "../../assets/images/EVENTS/sisters of mamry/SOM2.jpg";
import event11c from "../../assets/images/EVENTS/sisters of mamry/SOM3.jpg";
import event11d from "../../assets/images/EVENTS/sisters of mamry/SOM4.jpg";
import event11e from "../../assets/images/EVENTS/sisters of mamry/SOM5.jpg";
import event11f from "../../assets/images/EVENTS/sisters of mamry/SOM6.jpg";

import event12a from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN1.jpg";
import event12b from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN2.jpg";
import event12c from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN3.jpg";
import event12d from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN4.jpg";
import event12e from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN5.jpg";
import event12f from "../../assets/images/EVENTS/tuktukan cleanup/TUKTUKANCLEAN6.jpg";

import event13a from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice1.jpg";
import event13b from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice2.jpg";
import event13c from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice3.jpg";
import event13d from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice4.jpg";
import event13e from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice5.jpg";
import event13f from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice6.jpg";
import event13g from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice7.jpg";
import event13h from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice8.jpg";
import event13i from "../../assets/images/EVENTS/tuktukan rice/tuktukanrice9.jpg";

// import event14a from "../../assets/images/EVENTS/vista/vista1.jpg";
// import event14b from "../../assets/images/EVENTS/vista/vista2.jpg";
// import event14c from "../../assets/images/EVENTS/vista/vista3.jpg";

// import event15a from "../../assets/images/EVENTS/gmvietnam/gm1.jpg";
// import event15b from "../../assets/images/EVENTS/gmvietnam/gm2.jpg";
// import event15c from "../../assets/images/EVENTS/gmvietnam/gm3.jpg";
// import event15d from "../../assets/images/EVENTS/gmvietnam/gm4.jpg";
// import event15e from "../../assets/images/EVENTS/gmvietnam/gm5.jpg";
// import event15f from "../../assets/images/EVENTS/gmvietnam/gm6.jpg";
// import event15g from "../../assets/images/EVENTS/gmvietnam/gm7.jpg";
// import event15h from "../../assets/images/EVENTS/gmvietnam/gm8.jpg";
// import event15i from "../../assets/images/EVENTS/gmvietnam/gm9.jpg";

import event16a from "../../assets/images/EVENTS/2ndinterim/2nd1.jpg";
import event16b from "../../assets/images/EVENTS/2ndinterim/2nd2.jpg";
import event16c from "../../assets/images/EVENTS/2ndinterim/2nd3.jpg";
import event16d from "../../assets/images/EVENTS/2ndinterim/2nd4.jpg";
import event16e from "../../assets/images/EVENTS/2ndinterim/2nd5.jpg";
import event16f from "../../assets/images/EVENTS/2ndinterim/2nd6.jpg";
import event16g from "../../assets/images/EVENTS/2ndinterim/2nd7.jpg";
import event16h from "../../assets/images/EVENTS/2ndinterim/2nd8.jpg";
import event16i from "../../assets/images/EVENTS/2ndinterim/2nd9.jpg";

import event18a from "../../assets/images/EVENTS/lubang/18a.jpg";
import event18b from "../../assets/images/EVENTS/lubang/18b.jpg";
import event18c from "../../assets/images/EVENTS/lubang/18c.jpg";
import event18d from "../../assets/images/EVENTS/lubang/18d.jpg";
import event18e from "../../assets/images/EVENTS/lubang/18e.jpg";
import event18f from "../../assets/images/EVENTS/lubang/18f.jpg";
import event18g from "../../assets/images/EVENTS/lubang/18g.jpg";
import event18h from "../../assets/images/EVENTS/lubang/18h.jpg";
import event18i from "../../assets/images/EVENTS/lubang/18i.jpg";

import event19a from "../../assets/images/EVENTS/tipas_scholarship/19a.jpg";
import event19b from "../../assets/images/EVENTS/tipas_scholarship/19b.jpg";
import event19c from "../../assets/images/EVENTS/tipas_scholarship/19c.jpg";
import event19d from "../../assets/images/EVENTS/tipas_scholarship/19d.jpg";
import event19e from "../../assets/images/EVENTS/tipas_scholarship/19e.jpg";
import event19f from "../../assets/images/EVENTS/tipas_scholarship/19f.jpg";
import event19g from "../../assets/images/EVENTS/tipas_scholarship/19g.jpg";
import event19h from "../../assets/images/EVENTS/tipas_scholarship/19h.jpg";

import event20a from "../../assets/images/EVENTS/lubangvoc/20a.jpg";
import event20b from "../../assets/images/EVENTS/lubangvoc/20b.jpg";
import event20c from "../../assets/images/EVENTS/lubangvoc/20c.jpg";
import event20d from "../../assets/images/EVENTS/lubangvoc/20d.jpg";
import event20e from "../../assets/images/EVENTS/lubangvoc/20e.jpg";
import event20f from "../../assets/images/EVENTS/lubangvoc/20f.jpg";

import event21a from "../../assets/images/EVENTS/lubangground/21a.jpg";
import event21b from "../../assets/images/EVENTS/lubangground/21b.jpg";
import event21c from "../../assets/images/EVENTS/lubangground/21c.jpg";
import event21d from "../../assets/images/EVENTS/lubangground/21d.jpg";
import event21e from "../../assets/images/EVENTS/lubangground/21e.jpg";
import event21f from "../../assets/images/EVENTS/lubangground/21f.jpg";

import "./Events.css";

const eventsData = [
  {
    avatar: "FC",
    title: "Brgy. Bambang Clean Up Drive",
    subheader: "May 8, 2024",
    images: [event1a, event1b, event1c],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02YWLXzo5yq3nGxYxCHTgo75WuhLSMBJgkZSR5x78u7jK8gRXfRtdv1KYdZG4q21Zsl",
  },
  {
    avatar: "CM",
    title: "Brgy. Bambang Scholarship Granting & Rice Donation Ceremony.",
    subheader: "May 8, 2024",
    images: [
      event2a,
      event2b,
      event2c,
      event2d,
      event2e,
      event2f,
      event2g,
      event2h,
      event2i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid033nSoE5Tf3micA395px4bK2kgyq8MMFXrU9VZ6fH8scrQtrikMU5gZ5NMU6Um85kil",
  },
  {
    avatar: "FC",
    title: "Book Donations Distribution",
    subheader: "February 21, 2024",
    images: [event3a, event3b, event3c],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0h5nqaQSfpfdRDjHAiRSbUVnvgQEnsjJTZ4Fy2KD1uqP5kZxUjaWXgGYvv1oMX9XPl",
  },
  {
    avatar: "FC",
    title: "Christmas Donation & Scholarship Granting at Vista Mall",
    subheader: "December 15, 2023",
    images: [
      event4a,
      event4b,
      event4c,
      event4d,
      event4e,
      event4f,
      event4g,
      event4h,
      event4i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02JkmzowtynadQnudARTjjRxL6X5HhmJuzRw7ao9tCMAqPRRnSv69TD4H3dSo2twA2l",
  },
  {
    avatar: "FC",
    title: "Always With Us Conference",
    subheader: "October 12, 2023",
    images: [
      event5a,
      event5b,
      event5c,
      event5d,
      event5e,
      event5f,
      event5g,
      event5h,
      event5i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02Zm58P1NyxjpcfdeozhGJuzUu5ewvYu2MtHTHryuvQPggPRxSJPvMWVy9mLuRXaUCl",
  },
  {
    avatar: "FC",
    title: "Founding Ceremony",
    subheader: "June 15, 2023",
    images: [event6a, event6b, event6c],
    link: "https://fb.watch/shtMBmOXaJ/",
  },
  {
    avatar: "FC",
    title: "Clothes, Toys & School Supplies Donation (Lucena, Quezon)",
    subheader: "February 20, 2024",
    images: [event7a, event7b, event7c, event7d, event7e, event7f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02LdLBKQ6xat5FQeabVeDPuqvNWKaZotfFdezdxzsWGwQBHhufKPbWo6ELMqDxpxGzl",
  },
  {
    avatar: "FC",
    title: "Outreach Program at Brgy. Calzada Tipas, Taguig City",
    subheader: "September 15, 2023",
    images: [event8a, event8b, event8c, event8d, event8e, event8f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02sgXuuzwtBdwxaLr6rpU7Qiy5xREEhpb2VVTKvtMDNnVUYqNff9LTsqB9KMhVZKwal",
  },
  {
    avatar: "FC",
    title: "Brgy. Pinagsama, Taguig Outreach Program",
    subheader: "October 20, 2023",
    images: [event9a, event9b, event9c, event9d, event9e, event9f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0j5Z5METmtqzxtTHTxBFDzvzNaE2XaXqoys3yzTDNN1tMhRfDK8j8Arpe8xknkBRJl",
  },
  {
    avatar: "FC",
    title: "Rice and Korean Noodles Donations at Silang, Cavite",
    subheader: "March 12, 2024",
    images: [
      event10a,
      event10b,
      event10c,
      event10d,
      event10e,
      event10f,
      event10g,
      event10h,
      event10i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0SVJY2HrSC4ctE75S3uhhgsvYgwtdsWPTTJESbPiBELJ7JD8ekYr7sUCgpSWQEfYWl",
  },
  {
    avatar: "FC",
    title: "Scholarship Distribution at General Santos City",
    subheader: "March 29, 2024",
    images: [event17a, event17b, event17c, event17d, event17e, event17f],
    link: "https://www.facebook.com/share/v/pEeV5uSoWf7fC5pX/",
  },
  {
    avatar: "FC",
    title:
      "Japanese Board of Directors visited Sisters of Mary School in Silang, Cavite",
    subheader: "April 22, 2024",
    images: [event11a, event11b, event11c, event11d, event11e, event11f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0oMa1hVTxDpPzQtWGwfFwUpvVJxb8uT3RSvwEBFHR6Dh3Y9NpFdQTRzBzMv493TMTl",
  },
  {
    avatar: "FC",
    title: "Brgy. Tuktukan Clean Up Drive",
    subheader: "April 22, 2024",
    images: [event12a, event12b, event12c, event12d, event12e, event12f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid09ReAUx5s9RwH1TREskTv3jfvyy53GJubCPyEeXoonL7S3uL23iYBFksu9mCWSU6Ql",
  },
  {
    avatar: "FC",
    title: "Brgy. Tuktukan Rice Donation",
    subheader: "February 29, 2024",
    images: [
      event13a,
      event13b,
      event13c,
      event13d,
      event13e,
      event13f,
      event13g,
      event13h,
      event13i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0h5nqaQSfpfdRDjHAiRSbUVnvgQEnsjJTZ4Fy2KD1uqP5kZxUjaWXgGYvv1oMX9XPl",
  },
  // {
  //   avatar: "GM",
  //   title: "AWU FOUNDATION and HOMS Token at GMVietnam",
  //   subheader: "June 7, 2024",
  //   images: [
  //     event15a,
  //     event15b,
  //     event15c,
  //     event15d,
  //     event15f,
  //     event15g,
  //     event15h,
  //     event15i,
  //   ],
  //   link: "https://fb.watch/sDAJdA82sl/",
  // },
  {
    avatar: "GM",
    title: "AWU FOUNDATION 2nd Interim Board of Directors Meeting",
    subheader: "June 26, 2024",
    images: [
      event16a,
      event16b,
      event16c,
      event16d,
      event16e,
      event16f,
      event16g,
      event16h,
      event16i,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0vPNvoN9jrYdb3WkPH4cYuyGAcUzhz7NdeKn5Bua5Xfod2Qox3op75qcLNSCZfN79l",
  },
  {
    avatar: "GM",
    title:
      "Partnership with Municipality of Lubang (Korean Noodles Donations & Scholarships) ",
    subheader: "August 16, 2024",
    images: [
      event18a,
      event18b,
      event18c,
      event18d,
      event18e,
      event18f,
      event18g,
      event18h,
      event18i,
    ],
    link: "https://www.facebook.com/awufoundation/videos/373400785809284",
  },
  {
    avatar: "GM",
    title: "Rice Donations & Scholarships at Ligid Tipas, Taguig City",
    subheader: "October 09, 2024",
    images: [
      event19a,
      event19b,
      event19c,
      event19d,
      event19e,
      event19f,
      event19g,
      event19h,
    ],
    link: "https://www.facebook.com/awufoundation/posts/pfbid035iARVP1raRkK945PRL5vZdFe3pMEzdv3Z33seA8PN4C3SMwSTfDsEgueQeQCGkuKl",
  },
  {
    avatar: "GM",
    title:
      "Donations of TV, Tumblers, Basketballs and Volleyballs at the Lubang Vocational High School",
    subheader: "November 20, 2024",
    images: [event20a, event20b, event20c, event20d, event20e, event20f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid0Az4aEGBoxwnQcbGLQV85x4Qt1AKsdNo3xSHihEBqCjjMJCQrCQ5XaYYpZCyYRtrMl",
  },
  {
    avatar: "GM",
    title:
      "Scholarship and Groundbreaking at Municipality of Lubang, Philippines",
    subheader: "November 21, 2024",
    images: [event21a, event21b, event21c, event21d, event21e, event21f],
    link: "https://www.facebook.com/awufoundation/posts/pfbid02J4h4756FCiVSxp3qwQ6ZUpx8w9kV2m8z1eWUzFc6PYztHLtnWgvZoNSEkWACqQrpl",
  },
];

const sortedEventsData = eventsData.sort(
  (a, b) => new Date(a.subheader) - new Date(b.subheader)
);

const Events = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  const years = [
    ...new Set(eventsData.map((event) => event.subheader.split(" ")[2])),
  ];
  const months = [
    ...new Set(eventsData.map((event) => event.subheader.split(" ")[0])),
  ];

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const filteredEvents = sortedEventsData.filter((event) => {
    const [month, , year] = event.subheader.split(" ");
    return (
      (!selectedYear || year === selectedYear) &&
      (!selectedMonth || month === selectedMonth)
    );
  });

  return (
    <>
      <BannerHeader title="Events" subTitle="Always with us" url={board} />
      <div className="filter-container">
        <select value={selectedYear} onChange={handleYearChange}>
          <option value="">Select Year</option>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select value={selectedMonth} onChange={handleMonthChange}>
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="horizontal-events-container">
        {filteredEvents.map((event, index) => (
          <a key={index} href={event.link} className="event-container">
            <h3>{event.title}</h3>
            <p>{event.subheader}</p>
            <div className="image-gallery">
              {event.images.map((image, idx) => (
                <div className="image-container" key={idx}>
                  <img src={image} alt={`Event ${index} Image ${idx}`} />
                </div>
              ))}
            </div>
          </a>
        ))}
      </div>
    </>
  );
};

export default Events;
